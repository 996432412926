import styled from 'styled-components'
import Arrow from '../../../../assets/images/temps/arrow.png'
import { orange } from '../../../../styles/colors'

export const OverrideCss = styled.div`

  .react-multi-carousel-list {
    padding: 0;

    ul.react-multi-carousel-track {
      margin-bottom: 40px;
    }

    ul.react-multi-carousel-dot-list {
      li.react-multi-carousel-dot {
        button {
          border: 0;
          background: rgba(235, 112, 0, 0.5);
          height: 8px;
          width: 8px;
        }
        &--active {
          button {
            background: ${orange.medium};
          }
        }
      }
    }
  }

  .react-multiple-carousel {

    &__arrow {
      background-color: transparent;
      bottom: -7px;
      outline: none;
      top: auto;
      z-index: 1;

      &:hover {
        background: none;
      }

      &--right{

        &:before {
          content: "";
          border: none;
          bottom: 0;
          background: url(${Arrow});
          height: auto;
          position: absolute;
          width: 40px;
          height: 40px;
          left: 3px;
        }
      }

      &--left{

        &:before {
          content: '';
          border: none;
          bottom: 0;
          background: url(${Arrow});
          height: auto;
          position: absolute;
          transform: rotate(-180deg);
          width: 40px;
          height: 40px;
          left: 3px;
        }
      }
    }
  }
`
