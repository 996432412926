import { Link } from 'gatsby'
import React from 'react'
import ImageWebp from 'src/components/ImageWebp'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { CashbackSection } from './style'

const Cashback = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <CashbackSection
      id='com-cashback'
      className='bg-orange--extra d-md-flex align-items-center'
      aria-label='Mulher com o cartão Gold na mão com benefícios do Loop no Super App. Milhas, cashback, desconto na fatura e mais.'
    >
      <div className='container py-5'>
        <div className='row'>
          <div className='col-12 col-md-6'>
            <div>
              <div className='d-md-flex align-items-center'>
                <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-md-35 lh-xl-45 text-white mt-2 mt-md-0 mb-5 mb-md-0'>
                  <span className='d-md-block d-xl-inline'>Inter Loop: programa de</span> <span className='d-md-block'>pontos com benefícios</span> que voltam pra você
                </h2>
              </div>
              <div className='d-block d-md-none col-12 col-md-4 col-xl-5 d-flex justify-content-center'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/cartao-credito-loop/image.webp'
                  altDescription='Mulher com o cartão Gold na mão com benefícios do Loop no Super App. Milhas, cashback, desconto na fatura e mais.'
                />
              </div>
              <p className='fs-16 fs-md-18 lh-18 lh-lg-22 text-white mt-5 mt-xl-4 mb-md-0 pb-md-4 mr-md-2 mr-lg-5'>
                Use seu cartão de crédito Inter e ganhe pontos para trocar por cashback na conta, milhas, descontos na fatura, investimentos e cashback extra no Inter Shop.
              </p>
              <Link
                to='/pra-voce/cartoes/programa-de-pontos'
                title='Conheça o Inter Loop'
                className='btn btn--lg fs-14 fw-600 rounded-2 mx-auto text-none mt-3'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    element_name: 'Conheça o Inter Loop',
                    section_name: 'Inter Loop: programa de pontos com benefícios que voltam pra você',
                    redirect_url: 'https://inter.co/pra-voce/cartoes/programa-de-pontos',
                  })
                }}
              >
                Conheça o Inter Loop
              </Link>
            </div>
          </div>
        </div>
      </div>
    </CashbackSection>
  )
}

export default Cashback
